import axios from "axios";
import moment from "moment";

const api = axios.create({
    baseURL: 'https://minhablu.com.br/bluback',
});
const data = moment(localStorage.getItem('sessionDataAcesso')).add(1, 'h');
api.interceptors.request.use(req => {
    if (moment(data).isSameOrBefore(moment())){
        localStorage.removeItem('sessionToken');
        localStorage.removeItem('sessionNomuser');
        localStorage.removeItem('sessionPlano');
        localStorage.removeItem('sessionDataAcesso');
        localStorage.removeItem('sessionIdEmpresa');
        localStorage.removeItem('sessionId_usuario');
    }
    if (localStorage.getItem('sessionToken')){
        req.headers.Authorization = `Bearer ${localStorage.getItem('sessionToken')}`;
    }
    return req;
},
(err) => {
    console.log(err);
});

api.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401){
        localStorage.removeItem('sessionToken');
       // document.location = '/'; isso que tava dando problema osso !@#$%¨&¨%$#@@#$%¨&*
    }
    return error;
});

export default api;